<template>
  <div class="container mb-3">
    <div class="xnpanel panel-success p-3">
      <div class="panel-body">
       <div class="panel-heading mb-4">
       <span style="font-size:1em;" @click="sf(0)">{{cbk==1?'汽车桩 ':'单车桩 '}}</span><span style="font-size:1.5em;" @click="sf(1)">{{cid}}</span><span style="font-size:2em;" @click="sf(2)"> 电表</span><span style="font-size:3em;" @click="sf(3)"> 验准</span>
       </div>
       <b-form-group :style="fs">
         <div class="bv-example-row mb-4" v-if="isloading==1">
            <b-row>
                <b-col v-b-tooltip.hover title="电压(v)*电流(mA)*时长(秒)/1000/60/60">理论用电</b-col>
                <b-col v-b-tooltip.hover title="当前电量-初始电量(Wh)">电桩读表</b-col>
                <b-col v-b-tooltip.hover title="格式 xx分:xx秒">测试时长</b-col>
                <b-col v-b-tooltip.hover title="单位为伏(V)">工作电压</b-col>
            </b-row>
            <b-row class="border-bottom">
                <b-col class="text-primary">{{calcpow}}Wh</b-col>
                <b-col class="text-danger">{{factpow}}Wh</b-col>
                <b-col class="text-info">{{workingminutes}}:{{workingseconds}}</b-col>
                <b-col>{{pv}}V</b-col>
            </b-row>
         </div>
         <div class="bv-example-row">
            <template v-if="cbk==1">
            <b-row class="mb-3 border-bottom" v-show="selectedgun==0">
                <b-col>左枪电流</b-col>
                <b-col class="text-success">{{i0}}mA</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">V*I(A)*T(s)/60/60</b-col>
            </b-row>
            <b-row class="mb-3 border-bottom">
                <b-col>左枪电量</b-col>
                <b-col>{{w0}}Wh</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">
                    <b-form-radio v-model="selectedgun" value="0" :disabled="isloading==1">左枪</b-form-radio>
                </b-col>
            </b-row>
            <b-row class="mb-3 border-bottom" v-show="selectedgun==0">
                <b-col>左表参数</b-col>
                <b-col>{{spihf1}}</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">[(桩表-基准)/基准+1]*64</b-col>
            </b-row>
            <b-row class="mb-3 border-bottom" v-show="selectedgun==1">
                <b-col>右枪电流</b-col>
                <b-col class=" text-success">{{i1}}mA</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">V*I(A)*T(s)/60/60</b-col>
            </b-row>
            <b-row class="mb-3 border-bottom">
                <b-col>右枪电量</b-col>
                <b-col>{{w1}}Wh</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">
                    <b-form-radio v-model="selectedgun" value="1" :disabled="isloading==1">右枪</b-form-radio>
                </b-col>
            </b-row>
            <b-row class="mb-3 border-bottom" v-show="selectedgun==1">
                <b-col>右表参数</b-col>
                <b-col>{{spihf0}}</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">[(桩表-基准)/基准+1]*64</b-col>
            </b-row>
            </template>
            <template v-else>
            <b-row class="mb-3 border-bottom">
                <b-col>电桩电流</b-col>
                <b-col class="text-success">{{w1}}mA</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">V*I(A)*T(s)/60/60</b-col>
            </b-row>
            <b-row class="mb-3 border-bottom">
                <b-col>电桩电量</b-col>
                <b-col>{{w0}}Wh</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">常用:64/95/97</b-col>
            </b-row>
            <b-row class="mb-3 border-bottom" v-show="w1>0">
                <b-col>电表参数</b-col>
                <b-col>{{spihf1}}</b-col>
                <b-col class="d-none d-md-table-cell" v-if="fontid<2">[(桩表-基准)/基准+1]*64</b-col>
            </b-row>
            </template>
         </div>
         <b-button class="mt-4 btn-block" style="font-size:2em" :disabled="pageseconds<3" :variant="isloading?'outline-primary':'success'" @click="exchangeloading">{{isloading?btnstop:btnstart}}</b-button>
        </b-form-group>
      </div>
      <div class="panel-footer card card-body bg-light" v-show="isloading==0" :style="fs">
          这个功能用来验证电表是否准确。测试前先连接用电器，必需保证用电器平稳工作，最好使用电热丝用电器，比如：1000瓦的电热丝加热器，因为这类设备电流稳定，不会变化，测试更准确。
          开始前，先通电，再点“开始分析”，分析过程中，如果理论用电和电桩读表用电接近，说明充电桩的电表精度很高，如果差别比较明显，说明充电桩电表有误差。
      </div>
    </div>
  </div>
</template>

<script>
  import NoSleep from 'nosleep.js';
  let noSleep = new NoSleep();
  export default {
    name: 'powverify',
    mounted() {
      this.dopowverify();
      document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                noSleep.disable();
                if (this.timerid) clearInterval(this.timerid);
                if (this.isloading==1) this.isloading = 0;
            } else {
                this.pageseconds = 0;
                this.btnstart = '【刷新重试】';
            }
      });
    },
    beforeDestroy(){
        noSleep.disable();
        if (this.timerid) clearInterval(this.timerid);
    },
    data() {
      return {
        mac: this.$route.query.mac,
        cbk: this.$route.query.cbk,
        cid: this.$route.query.cid,
        ver: this.$route.query.ver,
        timerid:undefined,
        i0:0,
        i1:0,
        w0:0,
        w1:0,
        pv:220,
        spihf0:64,
        spihf1:64,
        selectedgun:0,
        pageseconds:0,
        startseconds:0,
        startpow:0,
        factpow:0,
        calcpow:0,
        workingminutes:'00',
        workingseconds:'00',
        sumpi:0,
        cntpi:0,
        fontid:1,
        btnstart:'开始分析',
        btnstop:'立即停止',
        fs:'font-size:1.5em;',
        isloading:0
      };
    },
    methods: {
      dopowverify(){
        let token = localStorage.getItem('token');
        let theapi = '/cmdsimple?tm='+new Date().getTime()+'&token='+token+'&mac='+this.mac+'&cmd=0';
        this.axios.get(theapi).then(() => {
            this.timerid = setInterval(this.fetchData, 1000);
        });
      },
      fetchData() {
        let token = localStorage.getItem('token');
        let theapi = '/powverify?tm='+new Date().getTime()+'&token='+token+'&mac='+this.mac+'&lod='+this.pageseconds;
        this.axios.get(theapi).then(axresp => {
          if (axresp.status==200) {
            let axdata = axresp.data;
            this.i0 = axdata.i0;
            this.i1 = axdata.i1;
            this.w0 = axdata.w0;
            this.w1 = axdata.w1;
            this.pv = axdata.pv;
            if (axdata.spihf0!=undefined) {
                this.spihf0 = axdata.spihf0;
                this.spihf1 = axdata.spihf1;
            }
            this.pageseconds++;
            if (this.isloading) {
                this.startseconds++;
                let m = parseInt(this.startseconds/60, 10);
                let s = this.startseconds % 60;
                if (m<10) {
                    this.workingminutes = '0'+m;
                } else {
                    this.workingminutes = ''+m;
                }
                if (s<10) {
                    this.workingseconds = '0'+s;
                } else {
                    this.workingseconds = ''+s;
                }
                if (this.cbk==1) {
                    if (this.selectedgun==0) {
                        this.sumpi += this.i0;
                        this.factpow = parseInt(this.w0-this.startpow, 10);
                    } else {
                        this.sumpi += this.i1;
                        this.factpow = parseInt(this.w1-this.startpow, 10);
                    }
                } else {
                    this.sumpi += this.w1;
                    this.factpow = parseInt(this.w0-this.startpow, 10);
                }
                this.cntpi++;
                this.calcpow = parseInt(this.pv*this.sumpi*this.startseconds/this.cntpi/1000/60/60, 10);
            }
          }
        });
      },
      exchangeloading() {
          this.isloading = 1-this.isloading;
          if (this.isloading==1) {
              this.sumpi = 0;
              this.cntpi = 0;
              this.startseconds = 0;
              if (this.cbk==1) {
                  if (this.selectedgun==0) {
                    this.startpow = this.w0;
                  } else {
                    this.startpow = this.w1;
                  }
              } else {
                this.startpow = this.w0;
              }
              noSleep.enable();
          } else {
              this.startpow = 0;
              noSleep.disable();
          }
      },
      sf(id) {
          this.fontid = id;
          if (id==0) this.fs = 'font-size:1em;';
          else if (id==1) this.fs = 'font-size:1.5em;';
          else if (id==2) this.fs = 'font-size:2em;';
          else {
              this.fs = 'font-size:3em;';
          }
      },
    }
  }
</script>
